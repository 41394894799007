import {
  Box,
  ChakraBaseProvider,
  Container,
  Text,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import { theme } from "./config/theme";
import Header from "./components/header/Header";
import bannerPicture from "./assets/banner.png";
import Footer from "./components/footer/Footer";
import Preloader from "./components/preloader/preloader";

const App = () => {
  return (
    <>
      <ChakraBaseProvider theme={theme}>
        <Preloader />
        <Header />
        <Box
          bgRepeat={"no-repeat"}
          bgPosition={"center"}
          bgSize={"cover"}
          bgImage={`url(${bannerPicture})`}
          h={{ base: "calc(100vh - 124px)", lg: "calc(100vh - 170px)" }}
          as="section"
        >
          <Container pt={20} maxW={{ base: "100%", lg: "1220px" }}>
            <Box
              width={"100%"}
              maxW={{ base: "343px", lg: "382px" }}
              padding={"32px 24px"}
              position={"relative"}
            >
              <Box position={"relative"} zIndex={1}>
                <Text fontSize={"md"} lineHeight={7} color={"white"}>
                  Welcome to Manna Grato!
                </Text>
                <Heading
                  mt={2}
                  mb={6}
                  fontSize={"28px"}
                  fontWeight={"semibold"}
                  color={"accent"}
                >
                  MEP Cost Consultants
                </Heading>
                <Text
                  fontSize={"lg"}
                  lineHeight={7}
                  color={"white"}
                  fontWeight={400}
                >
                  Knowledge. Competence. Value
                </Text>
              </Box>

              <Box
                zIndex={0}
                position={"absolute"}
                top={0}
                left={0}
                bg={"dark"}
                opacity={"75%"}
                h={"100%"}
                w={"100%"}
              />
            </Box>
          </Container>
        </Box>
        <Footer />
      </ChakraBaseProvider>
    </>
  );
};

export default App;
