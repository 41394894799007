export const SECTORS_DATA = {
	"Commercial & Retail": [
		"Tall Buildings",
		"Mixed Use Buildings",
		"Retail Programme Rollout",
	],
	"Residential & Leisure": [
		"Private & Affordable Residential",
		"Prime/Luxury Residential",
		"Hotels and Leisure",
		"Gyms & Restaurants",
	],
	"Healthcare & Education": [
		"Hospitals & Care Homes",
		"Walk-in Centres & GP Surgeries",
		"Education – Schools & Libraries",
		"University Accommodation",
	],
	"Industrial & Logistics": [
		"Data Centres",
		"Warehouses",
		"Distribution Centres",
	],
	"Rail & Aviation": [
		"London Underground (TFL): Depot Upgrades",
		"Network Rail: Electrification and Plant",
		"Points Heating Renewals",
		"Signalling Power Supplies",
		"HS2: Various New Stations",
		"Crossrail: Various Stations",
	],
	Utilities: [
		"HV Power & Substations",
		"Water Infrastructure",
		"Renewable Energy",
	],
};
