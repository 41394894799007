import { Box, Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import InLogoIcon from "../../assets/icons/InLogoIcon";

const Footer = () => {
	return (
		<Box bg={"dark"} h={"60px"} as="footer">
			<Container h={"100%"} maxW={{base: "100%", lg: "1220px"}}>
				<Flex
					h={"100%"}
					justifyContent={"space-between"}
					alignItems={"center"}
					color={"white"}
					fontWeight={500}
				>
					<Text
						fontSize={"md"}
						textDecoration={"underline"}
						color={"accent"}
						lineHeight={6}
					>
						<a href="mailto:info@mannagrato.com">info@mannagrato.com</a>
					</Text>
					<Text
						display={{base: "none", lg: "block"}}
						fontWeight={400}
						fontSize={"md"}
						color={"primaryGray"}
						lineHeight={6}
					>
						© 2023 Manna Grato
					</Text>
					<a
						rel="noreferrer"
						href="https://www.linkedin.com/company/manna-grato/"
						target="_blank"
					>
						<InLogoIcon />
					</a>
				</Flex>
			</Container>
		</Box>
	);
};

export default Footer;
