import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
	colors: {
		dark: "#26323E",
		accent: "#00BD9D",
		primaryGray: "#6B8899",
		secondaryGray: "#FCFBFB",
		divider: "#E2E2E2",
	},
});
