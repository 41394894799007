import { useEffect, useState } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import preloaderImage from "../../assets/preloader-img.png";

const Preloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
      return () => {
        window.removeEventListener("load", handleLoad);
      };
    }
  }, []);

  return (
    <>
      {loading && (
        <Flex
          pb={{ base: 2, lg: "110px" }}
          flexDirection={"column"}
          justifyContent="flex-end"
          h={"100vh"}
          className="preloader"
          bgImage={`url(${preloaderImage})`}
          bgSize={"cover"}
          bgRepeat={"no-repeat"}
          bgPosition={{ base: "-460px, 0", sm: "center" }}
        >
          <Text
            pl={{ base: 4, lg: "38px" }}
            pb={{ base: "58px", lg: "77px" }}
            fontWeight={700}
            fontSize={{ base: "36px", lg: "79px" }}
            lineHeight={{ base: "44.57px", lg: "98px" }}
            color={"dark"}
            className="preloader-text"
          >
            Bridging the Gap
          </Text>
          <Box w={"100%"} bg={"#00BD9D"} className="loading-bar"></Box>
        </Flex>
      )}
    </>
  );
};

export default Preloader;
