const InLogoIcon = () => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25C19.6274 25 25 19.6274 25 13Z"
				stroke="#00BD9D"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M10.0426 10.3262H7.55811V18.3472H10.0426V10.3262Z"
				fill="#00BD9D"
			/>
			<path
				d="M8.78762 9.28015C9.60107 9.28015 10.2569 8.61803 10.2569 7.79828C10.2569 6.97852 9.60107 6.31641 8.78762 6.31641C7.97417 6.31641 7.31836 6.97852 7.31836 7.79828C7.31836 8.61803 7.97417 9.28015 8.78762 9.28015Z"
				fill="#00BD9D"
			/>
			<path
				d="M14.015 14.143C14.015 13.0142 14.532 12.3458 15.5284 12.3458C16.4427 12.3458 16.8778 12.989 16.8778 14.143V18.3553H19.3497V13.2728C19.3497 11.1225 18.1327 10.082 16.4301 10.082C14.7275 10.082 14.015 11.4063 14.015 11.4063V10.328H11.6313V18.349H14.015V14.1367V14.143Z"
				fill="#00BD9D"
			/>
		</svg>
	);
};

export default InLogoIcon;
