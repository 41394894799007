import {
  Box,
  Container,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "../../assets/Logo";
import PlusIcon from "../../assets/icons/PlusIcon";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import { PICK_A_SERVICE_DATA } from "../../data/pickAServiceData";
import { SECTORS_DATA } from "../../data/sectorsData";
import MinusIcon from "../../assets/icons/MinusIcon";

// Pick a service
const PICK_A_SERVICE_DATA_HEADINGS = Object.keys(PICK_A_SERVICE_DATA);
const PICK_A_SERVICE_DATA_VALUES = Object.values(PICK_A_SERVICE_DATA);

// Sectors
const SECTORS_DATA_HEADINGS = Object.keys(SECTORS_DATA);
const SECTORS_DATA_VALUES = Object.values(SECTORS_DATA);

const Header = () => {
  const [pickServiceIndex, setPickServiceIndex] = useState(0);
  const [sectorIndex, setSectorIndex] = useState(0);
  // Mobile menu opening handler
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [openedModuleName, setOpenedModuleName] = useState(0);
  const [openedSubModuleName, setOpenedSubmoduleName] = useState("");

  return (
    <Box
      position={"relative"}
      bg={"dark"}
      h={{ base: "64px", lg: "110px" }}
      as="header"
    >
      <Flex
        display={{ base: "flex", lg: "none" }}
        position={"absolute"}
        p={"16px 16px 22px"}
        zIndex={999}
        bg={"white"}
        h={"calc(100vh - 64px)"}
        top={"100%"}
        w={"100vw"}
        right={isMenuOpen ? 0 : "100%"}
        transition={"0.2s"}
        flexDirection={"column"}
        overflowY={"scroll"}
      >
        <Box>
          <Box>
            <Flex
              fontWeight={600}
              fontSize={"18px"}
              lineHeight={"28px"}
              alignItems={"center"}
              justifyContent={"space-between"}
              h={"76px"}
              borderBottom={"1px solid #E2E2E2"}
              onClick={() => {
                setOpenedModuleName(
                  openedModuleName === "Pick a Service" ? "" : "Pick a Service"
                );
                setOpenedSubmoduleName("");
              }}
            >
              Pick a Service
              <Box
                transition={"0.3s"}
                transform={
                  openedModuleName === "Pick a Service"
                    ? "rotate(-180deg)"
                    : "none"
                }
              >
                <ArrowIcon />
              </Box>
            </Flex>
            {openedModuleName === "Pick a Service" && (
              <Box mt={6}>
                {PICK_A_SERVICE_DATA_HEADINGS.map((heading) => {
                  const isCurrentOptionOpened = heading === openedSubModuleName;
                  return (
                    <Box>
                      <Flex
                        h={"52px"}
                        onClick={() =>
                          setOpenedSubmoduleName(
                            isCurrentOptionOpened ? "" : heading
                          )
                        }
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Text
                          fontSize={"18px"}
                          lineHeight={7}
                          color={isCurrentOptionOpened ? "#00BD9D" : "#02273D"}
                        >
                          {heading}
                        </Text>
                        {isCurrentOptionOpened ? (
                          <MinusIcon />
                        ) : (
                          <PlusIcon color={"#011627"} />
                        )}
                      </Flex>

                      <Box mb={3}>
                        {(
                          (heading === openedSubModuleName &&
                            PICK_A_SERVICE_DATA[openedSubModuleName]) ||
                          []
                        ).map((value) => {
                          return (
                            <Flex gap={2} alignItems={"center"} h={10}>
                              <Box
                                h={2}
                                w={2}
                                borderRadius={"100%"}
                                bg={"accent"}
                              />
                              <Text fontSize={"14px"} lineHeight={"20px"}>
                                {value}
                              </Text>
                            </Flex>
                          );
                        })}{" "}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>

          <Box>
            <Flex
              fontWeight={600}
              fontSize={"18px"}
              lineHeight={"28px"}
              alignItems={"center"}
              justifyContent={"space-between"}
              h={"76px"}
              borderBottom={"1px solid #E2E2E2"}
              onClick={() => {
                setOpenedModuleName(
                  openedModuleName === "Sectors" ? "" : "Sectors"
                );
                setOpenedSubmoduleName("");
              }}
            >
              Sectors
              <Box
                transition={"0.3s"}
                transform={
                  openedModuleName === "Sectors" ? "rotate(-180deg)" : "none"
                }
              >
                <ArrowIcon />
              </Box>
            </Flex>
            {openedModuleName === "Sectors" && (
              <Box mt={6}>
                {SECTORS_DATA_HEADINGS.map((heading) => {
                  const isCurrentOptionOpened = heading === openedSubModuleName;
                  return (
                    <Box>
                      <Flex
                        h={"52px"}
                        onClick={() =>
                          setOpenedSubmoduleName(
                            isCurrentOptionOpened ? "" : heading
                          )
                        }
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Text
                          fontSize={"18px"}
                          lineHeight={7}
                          color={isCurrentOptionOpened ? "#00BD9D" : "#02273D"}
                        >
                          {heading}
                        </Text>
                        {isCurrentOptionOpened ? (
                          <MinusIcon />
                        ) : (
                          <PlusIcon color={"#011627"} />
                        )}
                      </Flex>

                      <Box mb={3}>
                        {(
                          (heading === openedSubModuleName &&
                            SECTORS_DATA[openedSubModuleName]) ||
                          []
                        ).map((value) => {
                          return (
                            <Flex gap={2} alignItems={"center"} h={10}>
                              <Box
                                h={2}
                                w={2}
                                borderRadius={"100%"}
                                bg={"accent"}
                              />
                              <Text fontSize={"14px"} lineHeight={"20px"}>
                                {value}
                              </Text>
                            </Flex>
                          );
                        })}{" "}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>

          <a
            rel="noreferrer"
            target="_blank"
            href="https://mannagrato-elearning.com/"
          >
            <Flex
              fontWeight={600}
              fontSize={"18px"}
              lineHeight={"28px"}
              alignItems={"center"}
              justifyContent={"space-between"}
              h={"76px"}
              borderBottom={"1px solid #E2E2E2"}
            >
              Traning portal
              <ArrowIcon />
            </Flex>
          </a>
        </Box>
        <Text
          mt={"calc(100vh - 354px)"}
          fontWeight={400}
          fontSize={"md"}
          color={"primaryGray"}
          lineHeight={6}
        >
          © 2023 Manna Grato
        </Text>
      </Flex>
      <Container h={"100%"} maxW={{ base: "100%", lg: "1220px" }}>
        <Flex
          h={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          color={"white"}
          fontWeight={500}
        >
          <Flex
            display={{ base: "none", lg: "flex" }}
            zIndex="dropdown"
            gap={5}
          >
            <Menu offset={[0, 42]} closeOnSelect={false}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    onClick={() => setPickServiceIndex(0)}
                    borderBottom={isOpen ? "1px solid #00BD9D" : ""}
                    color={isOpen ? "accent" : ""}
                    _hover={{
                      color: "accent",
                      borderBottom: "1px solid #00BD9D",
                    }}
                    role="group"
                  >
                    <Flex gap={1} alignItems={"center"}>
                      <Box
                        display={isOpen ? "block" : "none"}
                        _groupHover={{ display: "block" }}
                      >
                        <PlusIcon />
                      </Box>
                      <Box
                        display={isOpen ? "none" : "block"}
                        _groupHover={{ display: "none" }}
                      >
                        <PlusIcon color={"#ffffff"} />
                      </Box>
                      <Text fontWeight={500}>Pick a Service</Text>
                    </Flex>
                  </MenuButton>
                  <MenuList
                    h={"427px"}
                    w={"806px"}
                    borderRadius={0}
                    color={"dark"}
                    p={0}
                    border={"none"}
                  >
                    <MenuItem
                      py={"10"}
                      _hover={{ background: "none", cursor: "default" }}
                      _focus={{ background: "none" }}
                      height={"100%"}
                    >
                      <Flex height={"100%"} gap={10}>
                        <Flex
                          fontSize={"lg"}
                          flexDirection={"column"}
                          gap={6}
                          textAlign={"right"}
                          w={"305px"}
                        >
                          {PICK_A_SERVICE_DATA_HEADINGS.map(
                            (heading, index) => (
                              <Text
                                cursor={"pointer"}
                                _hover={{
                                  color: "accent",
                                }}
                                color={
                                  index === pickServiceIndex ? "accent" : "dark"
                                }
                                fontWeight={
                                  index === pickServiceIndex ? 600 : 400
                                }
                                key={index}
                                onClick={() => setPickServiceIndex(index)}
                              >
                                {heading}
                              </Text>
                            )
                          )}
                        </Flex>
                        <Box bg={"divider"} w={"1px"} height={"auto"} />
                        <Flex
                          w={"478px"}
                          fontSize={"md"}
                          flexDirection={"column"}
                          gap={4}
                        >
                          {PICK_A_SERVICE_DATA_VALUES[pickServiceIndex].map(
                            (value, index) => (
                              <Flex key={index} alignItems={"center"} gap={4}>
                                <Box
                                  h={2}
                                  w={2}
                                  borderRadius={"100%"}
                                  bg={"accent"}
                                />
                                <Text>{value}</Text>
                              </Flex>
                            )
                          )}
                        </Flex>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu offset={[-140, 42]} closeOnSelect={false}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    onClick={() => setSectorIndex(0)}
                    borderBottom={isOpen ? "1px solid #00BD9D" : ""}
                    color={isOpen ? "accent" : ""}
                    _hover={{
                      color: "accent",
                      borderBottom: "1px solid #00BD9D",
                    }}
                    role="group"
                  >
                    <Flex gap={1} alignItems={"center"}>
                      <Box
                        display={isOpen ? "block" : "none"}
                        _groupHover={{ display: "block" }}
                      >
                        <PlusIcon />
                      </Box>
                      <Box
                        display={isOpen ? "none" : "block"}
                        _groupHover={{ display: "none" }}
                      >
                        <PlusIcon color={"#ffffff"} />
                      </Box>
                      <Text fontWeight={500}>Sectors</Text>
                    </Flex>
                  </MenuButton>
                  <MenuList
                    h={"427px"}
                    w={"806px"}
                    borderRadius={0}
                    color={"dark"}
                    p={0}
                  >
                    <MenuItem
                      py={"10"}
                      _hover={{ background: "none", cursor: "default" }}
                      _focus={{ background: "none" }}
                      height={"100%"}
                    >
                      <Flex height={"100%"} gap={10}>
                        <Flex
                          fontSize={"lg"}
                          flexDirection={"column"}
                          gap={6}
                          textAlign={"right"}
                          w={"305px"}
                        >
                          {SECTORS_DATA_HEADINGS.map((heading, index) => (
                            <Text
                              cursor={"pointer"}
                              _hover={{
                                color: "accent",
                              }}
                              color={index === sectorIndex ? "accent" : "dark"}
                              fontWeight={index === sectorIndex ? 600 : 400}
                              key={index}
                              onClick={() => setSectorIndex(index)}
                            >
                              {heading}
                            </Text>
                          ))}
                        </Flex>
                        <Box bg={"divider"} w={"1px"} height={"auto"} />
                        <Flex
                          w={"478px"}
                          fontSize={"md"}
                          flexDirection={"column"}
                          gap={4}
                        >
                          {SECTORS_DATA_VALUES[sectorIndex].map(
                            (value, index) => (
                              <Flex key={index} alignItems={"center"} gap={4}>
                                <Box
                                  h={2}
                                  w={2}
                                  borderRadius={"100%"}
                                  bg={"accent"}
                                />
                                <Text>{value}</Text>
                              </Flex>
                            )
                          )}
                        </Flex>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box
            height={{ base: "19px", lg: "29px" }}
            width={{ base: "132px", lg: "206px" }}
            cursor={"pointer"}
            onClick={() => window.location.reload()}
          >
            <Logo />
          </Box>
          <Box display={{ base: "none", lg: "block" }}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://mannagrato-elearning.com/"
            >
              <Flex
                _hover={{ color: "accent", borderBottom: "1px solid #00BD9D" }}
                gap={1}
                alignItems={"center"}
                role="group"
              >
                <Box display={"none"} _groupHover={{ display: "block" }}>
                  <PlusIcon />
                </Box>
                <Box _groupHover={{ display: "none" }}>
                  <PlusIcon color={"#ffffff"} />
                </Box>
                <Text>Training portal</Text>
              </Flex>
            </a>
          </Box>
          <Flex
            display={{ base: "flex", lg: "none" }}
            h={8}
            w={8}
            border={"1px solid #F6F6F6"}
            borderRadius={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"2px"}
            className={isMenuOpen ? "opened" : "closed"}
            onClick={() => {
              setMenuOpen(!isMenuOpen);
              setOpenedModuleName("");
              setOpenedSubmoduleName("");
            }}
          >
            <Box
              w={"13.33px"}
              h={"1.5px"}
              borderRadius={"1.5px"}
              bgColor={"#00BD9D"}
            />
            <Box
              w={"13.33px"}
              h={"1.5px"}
              borderRadius={"1.5px"}
              bgColor={"#00BD9D"}
            />
            <Box
              w={"13.33px"}
              h={"1.5px"}
              borderRadius={"1.5px"}
              bgColor={"#00BD9D"}
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
