export const PICK_A_SERVICE_DATA = {
	"MEP Cost Management": [
		"MEP Option Appraisals and Feasibility Studies",
		"MEP Cost Planning and Engineering",
		"MEP Cost Modelling and Estimating",
		"MEP Value Engineering",
		"MEP Cost Benchmarking",
		"MEP Change Management",
		"Final Account Closeout",
		"Life Cycle Costing (CAPEX, OPEX)",
		"Whole Life Costing",
	],
	"Programme Management": [
		"Programme Cost Assurance",
		"Strategic PMO Cost Advisory",
		"Benchmarking and Market Insight",
		"Development Appraisals",
		"Value Management",
		"Procurement Route Advice",
		"Tendering and Contract Strategy",
		"Contract Administration",
		"Cost Governance/Assurance/Auditing",
	],
	"Claims & Dispute Resolution": [
		"Delay and Disruption Claims",
		"Extension of Time",
		"Site Conditions Claims",
		"Acceleration Claims",
		"Quantum Analysis",
	],
	"MEP Training & Development": [
        "Bespoke Training Programme (12 modules)",
        "MEP Measurement and Bill of Quantities",
        "Benchmarking and Cost Analysis",
        "APC Training and Mentorship"
    ],
};
